import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <main className="about-content">
          <div className="container sm">
            <div className="grid">
              <div className="column-xs-12">
                <h1>About Luiz</h1>
                <Image fixed={data.avatar.childImageSharp.fixed} style={{borderRadius: `50%`}} className="avatar" alt='Avatar' />
                <p>Graduate of Game Design at DigiPen Institute of Technology in Redmond, Washington.</p>
                <p>Passionate about playing and making games. Experienced with conducting and analyzing playtests. Team oriented with strong communication and management skills. Willing to test and apply new, creative ideas and technologies to create engaging user experiences. Approaches problems with an objective, goal-oriented mindset.</p>
                <h3>Skills</h3>
                <ul className="skills-list">
                  <div className="grid">
                    <div className="column-xs-12 column-sm-7">
                      <h4>Design &amp; Development</h4>
                      <li>Rapid Prototyping</li>
                      <li>Playtesting and Reporting Bugs</li>
                      <li>Reporting and Conducting Playtests</li>
                      <li>Whiteboxing and Wireframing (UX/UI Design)</li>
                      <li>Survey Data Analysis and Documentation</li>
                      <li>Shaders and Particles (Visual Effects)</li>
                      <h4>Game Engines & Programming</h4>
                      <li>Unity</li>
                      <li>Custom Engines</li>
                      <h4>Programming</h4>
                      <li>C/C++ and C#</li>
                    </div>
                    <div className="column-xs-12 column-sm-5">
                      <h4>Software &amp; Tools</h4>
                      <li>3D (Maya 3D, Blender)</li>
                      <li>Visual Studio</li>
                      <li>Version Control (Perforce, GitKraken, TortoiseSVN)</li>
                      <li>Unity Shader Graph and VFX Graph</li>
                      <li>Adobe (Photoshop, Premiere Pro)</li>
                      <li>Microsoft Office (Word, Excel, PowerPoint)</li>
                      <h4>Languages</h4>
                      <li>English (fluent)</li>
                      <li>Brazilian Portuguese (native)</li>
                      <li>Japanese (familiar)</li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/avatar.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
